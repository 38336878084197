/* eslint-disable no-unused-vars */
import React, { memo } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { getEventPageTranslation } from "../helpers/localization";
import { getRandomInt, hyphenateHeading } from "../helpers/general";
import { getDates, getOperators } from "../components/event-cards";

import { Link, useIntl } from "gatsby-plugin-react-intl";
import { copy } from "../components/copy-handling";
import { useMicroCopy } from "../hooks/microcopy";

const renderClipPaths = () => {
  return (
    <svg className="h-0" width="0%" height="0%" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clipHero0" clipPathUnits="objectBoundingBox">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.998,0.57 C1,0.812,0.895,1,0.704,0.996 C0.514,0.962,0.477,0.765,0.477,0.765 C0.477,0.765,0.386,0.948,0.178,0.855 C-0.023,0.765,-0.026,0.526,0.034,0.342 C0.093,0.158,0.285,-0.038,0.527,0.006 C0.767,0.049,0.976,0.329,0.998,0.57"
        />
      </clipPath>
      <clipPath id="clipHero1" clipPathUnits="objectBoundingBox">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.231,0.696 C0.052,0.78,-0.02,0.886,0.005,0.945 C0.042,1,0.27,1,0.606,0.825 C0.941,0.616,1,0.479,0.988,0.386 C0.938,0.294,0.734,0.386,0.669,0.368 C0.778,0.27,0.855,0.182,0.783,0.064 C0.712,-0.054,0.462,0.018,0.337,0.066 C0.211,0.113,0.065,0.174,0.054,0.253 C0.042,0.336,0.155,0.359,0.262,0.368 C0.089,0.425,0.006,0.534,0.022,0.594 C0.038,0.656,0.129,0.705,0.231,0.696"
        />
      </clipPath>
      <clipPath id="clipHero2" clipPathUnits="objectBoundingBox">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.489,0.001 C0.13,0.014,-0.014,0.257,0.001,0.486 C0.016,0.715,0.224,0.955,0.494,0.996 C0.764,1,0.981,0.757,0.999,0.5 C1,0.244,0.848,-0.013,0.489,0.001"
        />
      </clipPath>
    </svg>
  );
};

const ClippedImage = ({ image, clipIndex }) => {
  return (
    <GatsbyImage
      image={getImage(image)}
      alt=""
      className="w-full h-full"
      style={{ clipPath: `url(#clipHero${clipIndex})` }} />
  );
};

export const HeroSense = memo(function HeroSense({ title, ingress, image }) {
  const clipPathCount = 3;

  return (
    <div className="flex flex-row flex-wrap w-full">
      {renderClipPaths()}
      <div className="w-full lg:w-1/2 pt-6 pb-12 lg:py-0 lg:pr-12">
        {image && <ClippedImage image={getImage(image)} clipIndex={getRandomInt(clipPathCount)} />}
      </div>
      <div className="lg:flex lg:flex-col lg:justify-center w-full lg:w-1/2 lg:pl-12">
        {title && <h1 className="mb-6 lg:mb-8">{title}</h1>}
        {ingress && <div className="c-h1 text-white whitespace-pre-line" style={{ lineHeight: "1.1" }}>{ingress}</div>}
      </div>
    </div>
  );
});

export const HeroSenseEvents = ({ pickedPullups }) => {
  const { locale } = useIntl();
  const copies = useMicroCopy();
  const [currImage, setCurrImage] = React.useState(0);
  const slidesImages = pickedPullups;
  const lengthImages = Math.min(pickedPullups?.length, 3);
  const fontNormal = true;

  const goToNext = (setFunction, current, slideLength) => {
    if (slideLength) {
      setFunction(current === slideLength - 1 ? 0 : current + 1);
    }
  };

  React.useEffect(() => {
    setTimeout(goToNext, 8000, setCurrImage, currImage, lengthImages);
  }, [currImage, lengthImages]);

  if (!Array.isArray(slidesImages) || lengthImages <= 0) {
    return null;
  }

  const truncateText = (text, limit) => {
    if (!text || text?.length <= limit) {
      return text;
    }
    /* Matches the desired number of characters + following characters until the closest space etc. */
    const regExp = new RegExp(`^.{${limit}}[a-zA-ZåÅäÄöÖ]*`, "s");

    return regExp.exec(text)?.[0].concat("...");
  };

  return (
    <>
      {renderClipPaths()}
      <div className="relative lg:min-h-full">
        <div
          className=" sm:mx-0 lg:min-h-0 lg:flex lg:clipMainHero lg:flex-wrap lg:max-h-screen"
          style={{ minHeight: "calc(100vh + 24px)" }}
        >
          {pickedPullups.slice(0, lengthImages)?.map((node, i) => {
            const image = node?.headImage ?? node?.headImageVertical;
            return (
              <div key={node?.id} className="flex flex-wrap w-full">
                <div className="flex w-full lg:w-1/2">
                  <div
                    className={
                      i === currImage
                        ? "opacity-100 transform transition scale-100 ease-out duration-1000 w-full"
                        : "opacity-0 h-0 transform transition scale-100 sm:scale-125 ease-in duration-10"
                    }
                  >
                    <ClippedImage image={image} clipIndex={i} />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex items-center">
                  <div
                    className={
                      i === currImage
                        ? "opacity-100 transform transition scale-100 ease-out duration-1000 w-full"
                        : "opacity-0 h-0 transform transition scale-100 sm:scale-125 ease-in duration-10"
                    }
                  >
                    <div className="pt-8 lg:pt-0 lg:pl-12 xxl:pl-16">
                      <div className="mb-2 c-base-alt">{getDates(node?.eventDates)}</div>
                      <h1 className="c-hero mb-3">
                        {hyphenateHeading(node?.eventName, 16, locale)}
                      </h1>
                      <div className="hidden sm:block mb-2">
                        {truncateText(node?.eventDescription?.eventDescription, 270)}
                      </div>
                      <div className="sm:hidden mb-2">
                        {truncateText(node?.eventDescription?.eventDescription, 150)}
                      </div>
                      <div className="mb-6">{getOperators(node?.operators, fontNormal)}</div>
                      <div>
                        <Link
                          className="btn"
                          to={`/${getEventPageTranslation(locale)}/${node?.slug}`}
                        >
                          {copy(copies, "link.readmore", locale)}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
