import React from "react";
import PropTypes from "prop-types";

const VerticalHeadings = ({ heading }) => {
  if (!heading) return null;

  const verticalHeadingClasses = "absolute transform -translate-y-1/2";

  return (
    <div className="hidden sm:block lg:hidden xl:block sticky top-50vh c-h2 text-white whitespace-no-wrap ">
      <div
        className={`${verticalHeadingClasses} -left-26 xl:left-0 sm:ml-6 lg:ml-8 xl:ml-10`}
        style={{ writingMode: "vertical-lr" }}
      >
        {heading}
      </div>
      <div
        className={`${verticalHeadingClasses} -right-26 xl:right-0 sm:mr-6 lg:mr-8 xl:mr-10`}
        style={{ writingMode: "vertical-lr" }}
      >
        {heading}
      </div>
    </div>
  );
};

export default VerticalHeadings;

VerticalHeadings.propTypes = {
  heading: PropTypes.string.isRequired,
};
