import React from "react";
import { HeroSense } from "../components/hero-sense";
import EventCards from "../components/event-cards";
import EventCardsMedium from "../components/event-cards-medium";
import { Operators } from "../components/operator-cards";
import LinkButton from "./link-button";
import VerticalHeadings from "./vertical-headings";

const AllEventsLinkButton = ({ allEventsLink }) => {
  return (
    <LinkButton
      linkName={allEventsLink?.name}
      target={allEventsLink?.page?.slug}
      isExternal={false}
      className="mt-4 md:mt-8 lg:mt-12"
    />
  );
};

const EventSenseCombined = ({
  headingEvents,
  headingOperators,
  headingLatest,
  ongoingEvents,
  eventPosts,
  operatorPosts,
  categoryName,
  headImage,
  sensePageIngress,
  rightNowIngress,
  eventsIngress,
  operatorsIngress,
  allEventsLink,
}) => {

  return (
    <div>
      <VerticalHeadings heading={categoryName} />
      <div className="c-rich-text xl:max-w-layout-xl xxl:max-w-layout-xxl mx-auto">
        <section>
          <HeroSense
            title={categoryName}
            ingress={sensePageIngress?.sensePageIngress}
            image={headImage}
          />
        </section>
        {ongoingEvents?.length > 0 && (
          <>
            <section className="my-20 md:my-36 lg:my-40">
              <h1 className="mb-8">{headingLatest}</h1>
              {rightNowIngress?.rightNowIngress && (
                <div className="whitespace-pre-line lg:w-2/5">
                  {rightNowIngress?.rightNowIngress}
                </div>
              )}
              <EventCardsMedium eventPosts={ongoingEvents} />
              {eventPosts?.length === 0 && <AllEventsLinkButton allEventsLink={allEventsLink} />}
            </section>
          </>
        )}
        {eventPosts?.length > 0 && (
          <section className="my-20 md:my-36 lg:my-40">
            <h1 className="mb-8">{headingEvents}</h1>
            {eventsIngress?.eventsIngress && (
              <div className="whitespace-pre-line lg:w-2/5">{eventsIngress?.eventsIngress}</div>
            )}
            <EventCards eventPosts={eventPosts} />
            <AllEventsLinkButton allEventsLink={allEventsLink} />
          </section>
        )}
        {operatorPosts?.length > 0 && (
          <section className="my-20 md:my-36 lg:my-40">
            <h1 className="mb-8">{headingOperators}</h1>
            {operatorsIngress?.operatorsIngress && (
              <div className="mb-8 whitespace-pre-line lg:w-2/5">
                {operatorsIngress?.operatorsIngress}
              </div>
            )}
            <Operators operators={operatorPosts} />
          </section>
        )}
      </div>
    </div>
  );
};

export default EventSenseCombined;
