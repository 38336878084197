import { useState, useEffect } from "react";
import { filterEventsByCategories, filterEventsBySenseCategories, isOngoingEvent, loadOngoingEvents } from "../helpers/events";

const useOngoingEvents = (locale, timeLimit = 30, categoryIds = [], senseCategoryIds = []) => {
  const [ongoingEvents, setOngoingEvents] = useState([]);

  useEffect(() => {
    const getOngoingEvents = async () => {
      const result = await loadOngoingEvents(locale, timeLimit);
      const filteredByCategories = filterEventsByCategories(result, categoryIds);
      const filteredEvents = filterEventsBySenseCategories(filteredByCategories, senseCategoryIds);
      const now = new Date();

      const finalEvents = filteredEvents.filter((event) => isOngoingEvent(event, now, timeLimit));

      setOngoingEvents(finalEvents);
    };

    getOngoingEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, timeLimit, JSON.stringify(categoryIds, senseCategoryIds)]);

  return ongoingEvents;
};

export default useOngoingEvents;
