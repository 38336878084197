/* eslint-disable no-unused-vars */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import EventSenseCombined from "../components/event-sense-combined";

import { injectIntl } from "gatsby-plugin-react-intl";
import { copy } from "../components/copy-handling";
import { useMicroCopy } from "../hooks/microcopy";
import useOngoingEvents from "../hooks/useOngoingEvents";
import { removeOngoingEvents } from "../helpers/events";

const SenseTemplate = ({ data, intl, pageContext: { localizedSlugs = {} } }) => {
  const senseOperators = data?.senseOperators?.toimija;
  const eventPosts = data?.events;
  const senseCategoryId = data?.senseEvents?.id;
  const ongoingEventsTimeLimit = data?.siteSettings?.edges[0]?.node?.ongoingEventsTimeLimit;
  const copies = useMicroCopy();

  const {
    categoryName,
    seoDescription,
    sensePageIngress,
    headImage,
    rightNowIngress,
    eventsIngress,
    operatorsIngress,
    allEventsLink,
  } = data?.senseEvents;

  const ongoingEvents = useOngoingEvents(
    intl?.locale,
    ongoingEventsTimeLimit,
    [],
    [senseCategoryId],
  );

  const upcomingEvents = removeOngoingEvents(eventPosts, ongoingEvents)?.slice(0, 10);

  return (
    <Layout data={data} localizedSlugs={localizedSlugs}>
      <SEO title={categoryName} description={seoDescription?.seoDescription ?? sensePageIngress?.sensePageIngress}/>
      <div className="c-rich-text lg:mt-40">
        <EventSenseCombined
          headingEvents={copy(copies, "heading.events", intl?.locale)}
          headingOperators={copy(copies, "heading.operators", intl?.locale)}
          headingLatest={copy(copies, "heading.latest", intl?.locale)}
          ongoingEvents={ongoingEvents}
          eventPosts={upcomingEvents}
          operatorPosts={senseOperators}
          categoryName={categoryName}
          headImage={headImage}
          sensePageIngress={sensePageIngress}
          rightNowIngress={rightNowIngress}
          eventsIngress={eventsIngress}
          operatorsIngress={operatorsIngress}
          allEventsLink={allEventsLink}
        />
      </div>
    </Layout>
  );
};

export default injectIntl(SenseTemplate);

export const pageQuery = graphql`
  query sensePageQuery($slug: String!, $locale: String) {
    senseEvents: contentfulAistikategoria(slug: { eq: $slug }) {
      id
      slug
      categoryName
      seoDescription { 
        seoDescription
      }
      headImage {
        gatsbyImageData(aspectRatio: 1.77777778, layout: CONSTRAINED, width: 1200)
      }
      sensePageIngress {
        sensePageIngress
      }
      rightNowIngress {
        rightNowIngress
      }
      eventsIngress {
        eventsIngress
      }
      allEventsLink {
        ... on Node {
          ... on ContentfulSloganlinkki {
            name
            page {
              ... on Node {
                ... on ContentfulSivu {
                  slug
                }
              }
            }
          }
        }
      }
      operatorsIngress {
        operatorsIngress
      }
      tapahtuma {
        __typename
        id
        eventName
        slug
        node_locale
        headImage {
          gatsbyImageData(aspectRatio: 1.77777778, layout: CONSTRAINED, width: 800)
        }
        operators {
          ... on Node {
            ... on ContentfulToimija {
              id
              operatorName
            }
          }
        }
        categories {
          ... on Node {
            ... on ContentfulKategoria {
              id
              categoryName
            }
          }
        }
        eventDates {
          ... on Node {
            ... on ContentfulTapahtumanPaivamaarat {
              id
              startDate
              endDate
            }
          }
        }
      }
    }
    senseOperators: contentfulAistikategoria(slug: { eq: $slug }) {
      id
      slug
      toimija {
        id
        operatorName
        operatorWebsite
        node_locale
        locations {
          ... on Node {
            ... on ContentfulSijainti {
              id
              locationName
            }
          }
        }
        operatorLogo {
          gatsbyImageData(layout: FIXED, width: 90, height: 90)
        }
      }
    }
    ...SenseEventsFragment
    ...AllFragment
  }
`;
